import Image from "next/image";
import logoSquareWhite from "@@/public/logo-white-256x256.webp";

export const LogoSquare = ({
  title = "",
  width,
  height,
}: {
  title?: string;
  width?: number;
  height?: number;
}) => {
  return (
    <Image
      className="object-cover object-center h-full"
      src={logoSquareWhite}
      alt="Yacht Sector logo"
      title={title}
      width={width}
      height={height}
    />
  );
};
