"use client";
import { useEffect, useState } from "react";
import Link from "next/link";
import { LogoSquare } from "@/components/common-components/logo-square";
import { Icon } from "@iconify/react";
import { classes } from "@/utils/lang";
import { usePathname } from "next/navigation";

export const LandingMobileNav = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    setIsExpanded(false);
  }, [pathname]);

  return (
    <div
      className={classes({
        "flex lg:hidden justify-between h-full items-center px-2 bg-b1": true,
        "opacity-90": isExpanded === false,
      })}
    >
      <div>
        <Link
          href="/"
          className="flex items-center space-x-4 uppercase font-sans tracking-wider"
        >
          <div className="text-xl h-16 w-16 p-1">
            <LogoSquare title="Home" height={56} width={56} />
          </div>
          <span>Yacht Sector</span>
        </Link>
      </div>
      <div role="button" onClick={() => setIsExpanded(!isExpanded)}>
        <Icon icon="ph:list" width="24" height="24" />
      </div>
      {isExpanded && (
        <div className="fixed top-0 left-0 w-full h-screen bg-b1 px-2 z-50">
          <div className="h-16 flex justify-between items-center">
            <div>
              <Link
                href="/"
                className="flex items-center space-x-4 uppercase font-sans tracking-wider"
              >
                <div className="text-xl h-16 w-16 p-1">
                  <LogoSquare title="Home" height={56} width={56} />
                </div>
                <span>Yacht Sector</span>
              </Link>
            </div>
            <div role="button" onClick={() => setIsExpanded(!isExpanded)}>
              <Icon icon="ph:list" width="24" height="24" />
            </div>
          </div>
          <div className="space-y-6 font-sans mt-6 tracking-wide">
            <div>
              <Link href="/discover-landing">Discover Yachts</Link>
            </div>
            <div>
              <Link href="/why-yacht-sector">Why Yacht Sector</Link>
            </div>
            <div>
              <Link href="/faq">FAQ</Link>
            </div>
          </div>
          <div className="mt-12">
            <Link href="/app/auth/sign-in">
              <span className="btn-secondary !border-t1">Login</span>
            </Link>
          </div>
          <div className="mt-6">
            <Link href="/app/auth/sign-in">
              <span className="btn-primary">Sign up</span>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
